import React from "react";
import Navbar from "../components/Navbar";
import Catalog from "../components/Catalog";
import Footer from "../components/Footer";

export default function Library() {
  return (
    <>
      <Navbar />
      <Catalog />
      <Footer />
    </>
  );
}
